<!-- Author:孔德昊 -->

<!-- “选择对象与文件”页面 -->

<template>
  <div class="importmain">
    <div class="imain">
      <div class="header">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-selecting"></use>
        </svg>
        <!-- 选择对象与文件-->
        <span class="progress">{{
          $t("label.import.index.selectobjectandfiles")
        }}</span>
        <span>··············································</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-editgrey"></use>
        </svg>
        <!--  编辑字段映射 -->
        <span class="normal">{{ $t("label.import.index.editfield") }}</span>
        <span>··············································</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-importgrey"></use>
        </svg>
        <!-- 开始导入 -->
        <span class="normal">{{ $t("label.import.index.startimport") }}</span>
      </div>

      <div class="nav">
        <p>{{ $t("label.import.index.notice") }}</p>
        <!-- 注：-->
        <p>
          {{
            $t("label.import.step1.csvonly", {
              recordnumlimit: this.$store.state.numlimit,
            })
          }}<!--1、只支持csv格式的文件，最多单次导入{{recordnumlimit}}条记录（<span style="color: red">若需执行触发器，则最多导入2000条</span>），超出限制的记录，系统会自动忽略。若需导入更多数据，请先拆分为多个文件，分次导入。-->
        </p>
        <p>
          {{ $t("label.import.step1.chooseidorother")
          }}<!--2、可以选择使用id或名称匹配记录与填写查找字段。若需获取对应记录id等信息，可创建报表，并将记录名称与记录id设置为组列，再将报表导出。-->
        </p>
      </div>

      <div class="leg">
        <div class="legleft">
          <span class="des">{{
            $t("label.import.page1.selectobjfromdata")
          }}</span
          ><!--选择要导入数据的对象-->
          <div class="boxleft">
            <div class="blup">
              <el-tabs type="border-card" style="width: 100%" @tab-click="handleClick">
                <!-- 常用对象与全部对象列表 -->
                <el-tab-pane :label="$t('label.import.step1.commonobj')">
                  <ul>
                    <li
                      style="border: 1px solid #ededed; border-width: 0 0 1px 0"
                      v-for="(item, index) in CommonObjectList"
                      v-bind:key="index"
                      :class="{ bg: index === $store.state.isactive }"
                      @click="fn(item.id, index, item.label, item, 2)"
                    >
                      {{ item.label }}
                      <i class="el-icon-arrow-right"></i>
                      <svg class="icon iimg" aria-hidden="true">
                        <use href="#icon-smallyes"></use>
                      </svg>
                    </li>
                  </ul>
                </el-tab-pane>
                <el-tab-pane :label="$t('label.import.step1.allobj')">
                  <ul>
                    <li
                      style="border: 1px solid #ededed; border-width: 0 0 1px 0"
                      v-for="(item, index) in AllObjectList"
                      v-bind:key="index"
                      :class="{ bg: index === $store.state.isactive }"
                      @click="fn(item.id, index, item.label, item, 2)"
                    >
                      {{ item.label }}
                      <i class="el-icon-arrow-right"></i>
                      <svg class="icon iimg" aria-hidden="true">
                        <use href="#icon-smallyes"></use>
                      </svg>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <!-- 选择处理数据的方式 -->
        <div class="legcenter">
          <span class="des">{{
            $t("label.import.page1.selectwayofdealdata")
          }}</span
          ><!--选择处理数据的方式-->
          <div>
            <div class="boxcenternull" v-if="$store.state.boxcenternullshow">
              <span style="font-size: 14px; color: #888888">{{
                $t("label.import.page1.pleaseselectobj")
              }}</span
              ><!--请先选择对象-->
            </div>

            <div class="boxcenterfull" v-if="$store.state.boxcenterfullshow">
              <el-tabs
                type="border-card"
                style="width: 100%; font-size: 12px; color: #080707"
                @tab-click="swicthtype"
                v-model="defaultselect"
              >
                <!-- tab新建 -------------------------------------------------->
                <el-tab-pane :label="$t('label.custom.new')" :name="insert">
                  <div v-if="currentSeletedOptionId == 'userquotas'">
                    <div class="dinge">
                      <span
                        >{{ $t("label.import.step1.null")
                        }}<!--无可选项--></span
                      >
                    </div>
                  </div>
                  <div v-if="currentSeletedOptionId !== 'userquotas'">
                    <p class="selectword">
                      {{
                        $t("label.import.step1.matchway", {
                          labelNameshow: $store.state.labelName,
                        })
                      }}
                      <!-- 匹配{{$store.state.labelName}}方式 -->
                    </p>
                    <!-- 匹配客户方式 -->
                    <div style="margin-left: 17px">
                      <el-select
                        clearable
                        v-model="customer"
                        placeholder=" "
                        style="width: 90%; height: 28px"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in customerlist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div
                    v-if="
                      $store.state.currentSeletedOptionId ===
                      'accountandcontact'
                    "
                  >
                    <p class="selectword">
                      {{ $t("label.import.page1.suitedwayoflinkman") }}
                    </p>
                    <!--匹配联系人方式 -->
                    <div style="margin-left: 17px">
                      <el-select
                        clearable
                        v-model="contact"
                        placeholder=" "
                        style="width: 90%; height: 30px"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in contactslist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disabled"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="checkboxshow" v-if="$store.state.checkboxshow">
                    <el-checkbox v-model="checked1"
                      >{{ $t("label.data.file.the.trigger")
                      }}<!--执行触发器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked2"
                      >{{ $t("label.data.file.validation.rules")
                      }}<!--执行验证规则--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked3"
                      >{{ $t("label.dataimport.isdupecatcher")
                      }}<!--执行查重过滤器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked4"
                      >{{ $t("label.dataimport.isSharingRule")
                      }}<!--执行共享规则--></el-checkbox
                    >
                  </div>
                </el-tab-pane>

                <!-- tab更新 -------------------------------------------------------------------->
                <el-tab-pane
                  :label="$t('label.dataimport.type.update')"
                  :name="update"
                  :disabled="updateshow"
                >
                  <div v-if="currentSeletedOptionId == 'userquotas'">
                    <div class="dinge">
                      <span
                        >{{ $t("label.import.step1.null")
                        }}<!--无可选项--></span
                      >
                    </div>
                  </div>
                  <div v-if="currentSeletedOptionId !== 'userquotas'">
                    <p class="selectword">
                      {{
                        $t("label.import.step1.matchway", {
                          labelNameshow: $store.state.labelName,
                        })
                      }}
                      <!-- 匹配{{$store.state.labelName}}方式 -->
                    </p>
                    <div style="margin-left: 17px">
                      <el-select
                        v-model="customer"
                        placeholder=" "
                        style="width: 90%"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in customerlist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disabled"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div
                    v-if="
                      currentSeletedOptionId === 'accountandcontact' ||
                      currentSeletedOptionId === ''
                    "
                  >
                    <p class="selectword">
                      {{ $t("label.import.page1.suitedwayoflinkman") }}
                    </p>
                    <!--匹配联系人方式-->
                    <div style="margin-left: 17px">
                      <el-select
                        v-model="contact"
                        placeholder=" "
                        style="width: 90%; height: 30px"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in contactslist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="checkboxshow" v-if="$store.state.checkboxshow">
                    <el-checkbox v-model="checked1"
                      >{{ $t("label.data.file.the.trigger")
                      }}<!--执行触发器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked2"
                      >{{ $t("label.data.file.validation.rules")
                      }}<!--执行验证规则--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked3"
                      >{{ $t("label.dataimport.isdupecatcher")
                      }}<!--执行查重过滤器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked4"
                      >{{ $t("label.dataimport.isSharingRule")
                      }}<!--执行共享规则--></el-checkbox
                    >
                  </div>
                </el-tab-pane>

                <!-- tab更新并新建 ----------------------------------------------------------->
                <el-tab-pane
                  :label="$t('vue_label_import_addandupdate')"
                  :name="upsert"
                  :disabled="upsertshow"
                >
                  <div v-if="currentSeletedOptionId == 'userquotas'">
                    <div class="dinge">
                      <span
                        >{{ $t("label.import.step1.null")
                        }}<!--无可选项--></span
                      >
                    </div>
                  </div>

                  <div v-if="currentSeletedOptionId !== 'userquotas'">
                    <p class="selectword">
                      {{
                        $t("label.import.step1.matchway", {
                          labelNameshow: $store.state.labelName,
                        })
                      }}
                      <!-- 匹配{{$store.state.labelName}}方式 -->
                    </p>
                    <div style="margin-left: 17px">
                      <el-select
                        v-model="customer"
                        placeholder=" "
                        style="width: 90%"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in customerlist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>

                  <div
                    v-if="
                      currentSeletedOptionId === 'accountandcontact' ||
                      currentSeletedOptionId === ''
                    "
                  >
                    <p class="selectword">
                      {{ $t("label.import.page1.suitedwayoflinkman") }}
                    </p>
                    <div style="margin-left: 17px">
                      <el-select
                        v-model="contact"
                        placeholder=" "
                        style="width: 90%; height: 30px"
                        class="elselect"
                        size="mini"
                      >
                        <el-option
                          v-for="item in contactslist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="checkboxshow" v-if="$store.state.checkboxshow">
                    <el-checkbox v-model="checked1"
                      >{{ $t("label.data.file.the.trigger")
                      }}<!--执行触发器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked2"
                      >{{ $t("label.data.file.validation.rules")
                      }}<!--执行验证规则--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked3"
                      >{{ $t("label.dataimport.isdupecatcher")
                      }}<!--执行查重过滤器--></el-checkbox
                    >
                    <br />
                    <el-checkbox v-model="checked4"
                      >{{ $t("label.dataimport.isSharingRule")
                      }}<!--执行共享规则--></el-checkbox
                    >
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div class="legright">
          <span class="des">{{
            $t("label.import.page1.selectimportfiled")
          }}</span>
          <!--选择要导入的文件-->
          <div class="boxright">
            <div class="boxrightnull" v-if="$store.state.boxrightnullshow">
              <span style="font-size: 14px; color: #888888">{{
                $t("label.import.page1.pleaseselectwayofdealdata")
              }}</span>
              <!-- 请先选择处理方式-->
            </div>
            <div class="boxrightfull" v-if="$store.state.boxrightfullshow">
              <el-upload
                accept=".csv"
                class="upload-demo"
                drag
                :action="action"
                :before-upload="beforeUpload"
                ref="upload"
                :limit="1"
                :http-request="httpRequest"
                :on-change="handleChange"
                :file-list="fileList"
                :on-remove="removefile"
              >
                <div class="el-upload__text" style="line-height: 14">
                  {{ $t("label.import.page1.uploadcsvfiled") }}
                  <!-- 拖拽或点击上传CSV文件-->
                </div>
              </el-upload>
              <div class="uptype">
                <p class="selectword" style="margin-left: 0px">
                  {{ $t("label.import.page1.selectcodingtype") }}
                </p>
                <!--选择编码类型-->
                <el-select
                  v-model="bianma"
                  placeholder=" "
                  class="elselect"
                  size="mini"
                >
                  <el-option
                    v-for="item in optionsbianma"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 上一步下一步按钮 -->

      <div class="Buttondiv">
        <div class="buttons">
          <el-button class="lastButton" @click="lastButton()">{{
            $t("label.import.page1.laststep")
          }}</el-button>
          <!--上一步-->
          <el-button class="nextButton" @click="disnextButton()" v-if="nextno"
            >{{ $t("label.import.page1.nextstep") }}
            <!--下一步--></el-button
          >
          <el-button class="nextButton" @click="buttonchufaqi()" v-if="chufaqi"
            >{{ $t("label.import.page1.nextstep") }}
            <!--下一步--></el-button
          >
          <!-- </el-tooltip> -->

          <el-button class="nextButton" @click="nextButton()" v-if="nextyes"
            >{{ $t("label.import.page1.nextstep") }}
            <!--下一步--></el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jschardet from "jschardet";
import Papa from "papaparse";

export default {
  props: {
    customerlist: { type: Array },
    contactslist: { type: Array },
    CommonObjectList: { type: Array },
    AllObjectList: { type: Array },
  },
  data() {
    return {
      // AllObjectListshow:false,
      chufaqi: false,
      customer: this.$store.state.customer,
      contact: this.$store.state.contact,
      nextno: true,
      nextyes: false,
      fileList: [],
      updateshow: false,
      upsertshow: false,
      insert: "insert",
      update: "update",
      upsert: "upsert",
      labelName: this.$store.state.labelName,
      action: "test/",
      defaultselect: "insert",
      checked1: this.$store.state.checked1,
      checked2: this.$store.state.checked2,
      checked3: this.$store.state.checked3,
      checked4: this.$store.state.checked4,
      boxcenternullshow: this.$store.state.boxcenternullshow,
      boxcenterfullshow: this.$store.state.boxcenterfullshow,
      boxrightnullshow: true,
      boxrightfullshow: false,
      optionsbianma: [
        {
          value: "UTF-8",
          label: "UTF-8",
        },
      ],
      bianma: "UTF-8",
      currentSeletedOptionId: "",
      swicthtablebal: "",
    };
  },
  watch: {
    //触发器
    checked1() {
      this.$store.commit("getchecked1", this.checked1);
      if (this.checked1 === true) {
        if (this.$store.state.curdata.length > 2000) {
          //执行触发器时，校验文件长度是否大于2000
          this.chufaqi = true;
          this.nextno = false;
          this.nextyes = false;
        }
      } else {
        if (this.$store.state.curdata.length >= 1) {
          this.chufaqi = false;
          this.nextno = false;
          this.nextyes = true;
        } else {
          this.chufaqi = false;
          this.nextno = true;
          this.nextyes = false;
        }
      }
    },
    //验证规则
    checked2() {
      this.$store.commit("getchecked2", this.checked2);
    },
    //查重规则
    checked3() {
      this.$store.commit("getchecked3", this.checked3);
    },
    //共享规则
    checked4() {
      this.$store.commit("getchecked4", this.checked4);
    },
    // 匹配客户方式
    customer() {
      this.$store.commit("getcustomer", this.customer);
    },
    // 匹配联系人方式
    contact() {
      this.$store.commit("getcontact", this.contact);
    },
  },
  mounted() {
    this.$store.commit(
      "getswicthtablebal",
      this.$i18n.t("label.import.page1.newcreate")
    );
  },
  methods: {
    // 切换tab时，清空下标
    handleClick(){
      this.$store.commit('getisactive', '')
    },
    removefile() {
      this.nextno = true;
      this.nextyes = false;
      this.chufaqi = false;
    },
    handleChange() {
      this.nextno = false;
      this.chufaqi = false;
      this.nextyes = true;
    },
    nextButton() {
      this.$router.push({
        path: `/importModule/step2`,
      });
    },
    disnextButton() {
      this.$message({
        message: this.$i18n.t("label.import.step1.choosecsv"), //请选择CSV文件
        type: "warning",
      });
    },
    buttonchufaqi() {
      this.$message({
        message: this.$i18n.t("label.import.step1.above2000"), //执行触发器时，最多单次导入2000条记录。请拆分为多个文件，分次导入。
        type: "warning",
      });
    },
    lastButton() {
      this.$router.push({
        path: `/importModule`,
      });
    },

    // 切换tab
    swicthtype(tab) {
      this.swicthtablebal = tab.label; //选中的中文tab
      this.$emit("swicthtablebal", this.swicthtablebal);
      this.$store.commit("getdefaultselect", this.defaultselect);
      if (this.$store.state.defaultselect === "insert") {
        this.customer = "";
        this.contact = "";
        this.$parent.customerlist[1].disabled = true;
        this.$parent.contactslist[3].disabled = true;
      } else {
        this.$parent.customerlist[1].disabled = false;
        this.$parent.contactslist[3].disabled = false;
      }
    },
    // 解析csv文件
    httpRequest(param) {
      let fileObj = param.file; // 相当于input里取得的files
      let fReader = new FileReader();
      fReader.readAsDataURL(fileObj);
      fReader.onload = (evt) => {
        // 检查编码
        let encoding = this.checkEncoding(evt.target.result);
        // 将csv转换成二维数组
        Papa.parse(fileObj, {
          encoding,
          complete: (res) => {
            // UTF8 \r\n与\n混用时有可能会出问题
            let data = res.data;
            //去除最后的空行
            data.pop();
            this.filename = fileObj.name;
            this.$store.commit("getfileObj", this.filename);
            let curdata = JSON.parse(JSON.stringify(data));
            this.$store.commit("getcurdata", curdata);
            if (this.$store.state.curdata.length > 30000) {
              //校验文件长度是否大于30000
              this.$message({
                message: this.$i18n.t("label.import.step1.higher30000"), //不执行触发器时，最多单次导入30000条记录。请拆分为多个文件，分次导入。
                type: "warning",
              });
              this.nextno = true;
              this.nextyes = false;
            } else if (this.$store.state.checked1 === true) {
              if (this.$store.state.curdata.length > 2000) {
                //执行触发器时，校验文件长度是否大于2000
                this.chufaqi = true;
                this.nextno = false;
                this.nextyes = false;
              }
            }
          },
          // },
        });
      };
    },
    // 检查编码，引用了 jschardet
    
    checkEncoding(base64Str) {
      // 这种方式得到的是一种二进制串
      let str = atob(base64Str.split(";base64,")[1]);
      // 要用二进制格式
      let encoding = jschardet.detect(str);
      encoding = encoding.encoding;
      if (encoding == "windows-1252") {
        // 有时会识别错误（如UTF8的中文二字）
        encoding = "ANSI";
      }
      return encoding;
    },

    //上传文件限制格式和大小
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "csv";
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!extension) {
        this.$message({
          // showClose: true,
          message: this.$i18n.t("label.import.page1.limitedcsv"), //上传文件只能是csv格式
          type: "warning",
        });
      }
      if (!isLt2M) {
        this.$message({
          // showClose: true,
          message: this.$i18n.t("label.import.page1.alertfiledsize"), //上传文件大小不能超过100MB
          type: "warning",
        });
      }
      this.$store.commit("getupfile", file);
      return extension && isLt2M;
    },
    // 点击事件
    fn(id, index, label, item, n) {
      this.customer = this.$parent.customerlist[0].label;
      if (n == 2) {
        this.labelName = label;
        this.$store.commit("getlabelName", this.labelName);
        this.$store.commit("getid", id);
        this.$store.commit("getisactive", index);
        this.currentSeletedOptionId = id;
        this.$store.commit(
          "getcurrentSeletedOptionId",
          this.currentSeletedOptionId
        );

        if (id === "userquotas") {
          //如果点击定额或联系人和客户
          this.$store.state.checkboxshow = false;
          this.updateshow = true;
          this.upsertshow = true;
          this.$store.state.defaultselect = "insert";
          this.$store.state.checked1 = false;
          this.$store.state.checked2 = false;
          this.$store.state.checked3 = false;
          this.$store.state.checked4 = false;
          this.$store.state.contact = "";
          this.$store.state.customer = "";
        } else if (id === "accountandcontact") {
          this.$store.state.checkboxshow = false;
        } else {
          this.updateshow = false;
          this.upsertshow = false;
          this.$store.state.checkboxshow = true;
        }

        this.boxcenterfullshow = true; //中间有内容的盒子展示
        this.$store.commit("getboxcenterfullshow", this.boxcenterfullshow); //传递到vuex

        this.boxcenternullshow = false; //中间无内容的盒子不展示
        this.$store.commit("getboxcenternullshow", this.boxcenternullshow); //传递到vuex

        this.boxrightnullshow = false; //右边盒子无内容的盒子展示
        this.$store.commit("getboxrightnullshow", this.boxrightnullshow);

        this.boxrightfullshow = true;
        this.$store.commit("getboxrightfullshow", this.boxrightfullshow);
      }
      if (id !== "accountandcontact") {
        this.$store.state.contact = "";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/importModule/step2") {
        // eslint-disable-next-line no-constant-condition
        if ((vm.id = "accountandcontact")) {
          vm.checkboxshow = false;
        }
        // 中间盒子显示
        vm.$store.commit("getboxcenterfullshow", true);
        vm.$store.commit("getboxcenternullshow", false);
        //右边盒子显示
        vm.$store.commit("getboxrightfullshow", true);
        vm.$store.commit("getboxrightnullshow", false);
        vm.$store.commit("getid", vm.$store.state.id);
        vm.defaultselect = vm.$store.state.defaultselect;
        vm.customer = vm.$store.state.customer;
        vm.contact = vm.$store.state.contact;
        vm.currentSeletedOptionId = vm.$store.state.currentSeletedOptionId;
      } else {
        // 中间盒子不显示
        vm.$store.commit("getboxcenterfullshow", false);
        vm.$store.commit("getboxcenternullshow", true);
        // 右边盒子不显示
        vm.$store.commit("getboxrightfullshow", false);
        vm.$store.commit("getboxrightnullshow", true);
        vm.$store.commit("getid", ""); //常用对象和全部对象选中的值设空
        // vm.curdata = "";
        vm.id = "";
        vm.checked1 = false; //执行触发器
        vm.checked2 = false; //执行验证规则
        vm.checked3 = false; //执行查重过滤器
        vm.checked4 = false; //执行共享规则
        vm.defaultselect = "insert"; //默认选中“新建”
        vm.$parent.customerlist[1].disabled = true;
        vm.$parent.contactslist[3].disabled = true;
        vm.customer = "";
        vm.contact = "";
        vm.currentSeletedOptionId = "";
        vm.$store.commit("getisactive", "");
      }
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep .li {
  border: 1px solid #ededed !important;
}
::v-deep .el-input__inner {
  border-radius: 2px;
  border: 1px solid #e3e2e2;
}
.dinge {
  color: #888;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
}

.distip {
  width: 150px;
  height: 23px;
  background-color: #006dcc;
  position: absolute;
  right: 110px;
  bottom: 276px;
}
.nextButton {
  background-color: #006dcc;
  color: #fff;
}
.lastButton {
  color: #666;
  border: 1px solid #e2e2e2;
}
.lastButton:hover {
  background-color: #fff;
  color: #666;
  border: 1px solid #e2e2e2;
}
.el-icon-arrow-right {
  float: right;
  padding-top: 12px;
  padding-right: 10px;
}
.el-checkbox {
  color: #080707;
  margin-top: 12px;
}
::v-deep .el-tabs--border-card {
  border: none;
  box-shadow: none;
}
::v-deep .element.style {
  width: 100%;
  height: 70%;
}
::v-deep .is-active {
  color: #080707 !important;
  background-color: #dce9ff !important;
}
::v-deep .is-top {
  background-color: #fff;
  color: #080707 !important;
}
::v-deep .el-tabs__nav-scroll {
  border-bottom: 1px solid #d8d8d8;
}
::v-deep .el-tabs__item {
  margin-top: 0 !important;
}
.iimg {
  display: none;
}
.bg {
  background-color: #dceeff;
  color: #006dcc;
  .el-icon-arrow-right {
    display: none;
  }
  .iimg {
    display: block;
    float: right;
    margin-top: 12px;
    margin-right: 10px;
  }
}
ul > li:hover {
  background-color: #dceeff;
}
ul > li {
  line-height: 35px;
  padding-left: 10px;
  font-size: 12px;
  color: #888888;
}
::v-deep .el-tabs__content {
  padding: 0 !important;
  overflow: auto;
  height: 257px;
}

.blup {
  width: 100%;
  display: flex;
  ::v-deep .el-tabs {
    width: 100%;
    font-size: 12px;
    color: #080707;
  }
  .blupleft {
    width: 23%;
    height: 50px;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
  }
}
.blupright {
  width: 23%;
  height: 50px;
  font-size: 12px;
  color: #080707;
  letter-spacing: 0;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}
.importmain {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  // border-radius: 4px;
  margin: 10px;

  .imain {
    width: 100%;
    height: 100%;
    padding: 0 140px 0 140px;
    .header {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .nav {
      width: 100%;
      height: 80px;
      p {
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
    }

    .leg {
      width: 100%;
      // min-height: 338px;
      margin-top: 80px;
      display: flex;
      justify-content: center;
      .legleft {
        width: 30%;
        .boxleft {
          width: 100%;
          border: 1px solid #d8d8d8;
          border-radius: 3px;
          overflow: auto;
        }
      }
      .legcenter {
        width: 30%;
        margin: 0 60px;
        .boxcenternull,
        .boxcenterfull {
          width: 100%;
          height: 300px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .boxcenternull {
          border: 1px dashed #d8d8d8;
        }
        .boxcenterfull {
          border: 1px solid #d8d8d8;
        }
      }
      .el-select {
        width: 100%;
      }
      .legright {
        width: 30%;
        height: 100%;
        .boxrightnull,
        .boxrightfull {
          width: 100%;
          height: 300px;
          border-radius: 3px;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .boxrightnull {
          border: 1px dashed #d8d8d8;
        }

        // upload尺寸
        ::v-deep .boxrightfull {
          border: 1px #d8d8d8;
          position: relative;
          .upload-demo {
            width: 100%;
            height: 70%;
            position: absolute;
            top: 0;
            .el-upload {
              width: 100% !important;
              height: 100%;
              .el-upload-dragger {
                width: 100%;
                height: 100%;
                border: 1px solid #d8d8d8;
                border-radius: 3px;
              }
            }
          }
        }

        ::v-deep .el-upload-list__item:first-child {
          margin-top: -4px;
          font-size: 4px;
        }

        .uptype {
          width: 100%;
          position: absolute;
          bottom: 0px;
        }
      }
    }
    .Buttondiv {
      // margin-top: 180px;
      padding-top: 39px;
      float: right;
      outline: none;
      height: 60px;
    }
  }
}

.normal {
  font-size: 16px;
  color: #191717;
  margin: 0 5px;
}
.progress {
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  color: #006dcc;
  letter-spacing: 0;
  margin: 0 5px;
}
.header > img {
  width: 36px;
  height: 36px;
  margin-left: 6px;
}
.des {
  font-family: SourceHanSansCN-Normal;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 38px;
}
::v-deep .el-cascader__dropdown {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-scrollbar__wrap {
        .el-cascader-menu__list {
          border: none !important;
          // box-shadow:0 2px 4px 0 rgba(0,0,0,0.25) !important;
          // border-radius: 3px !important;
        }
      }
    }
  }
}

.selectword {
  font-size: 12px;
  color: #080707;
  margin: 15px 0 2px 17px;
}
.checkboxshow {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  padding-left: 17px;
  font-size: 12px;
  color: #080707;
  font-family: SourceHanSansCN-Normal;
}
::v-deep .el-upload-list__item-name {
  width: 100% !important;
  height: 45px !important;
  font-size: 17px !important;
  line-height: 45px;
}
::v-deep .el-icon-close {
  font-size: 20px !important;
  margin-top: 8px;
}
</style>
